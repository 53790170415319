<template>
    <div>
        <styled-interface
            hide-toolbar
            no-padding>
            <onboarding-header
                :dealer="dealer">
                <template #actions>
                    <action-button
                        icon="indicator-error"
                        position="left"
                        light
                        normal-text
                        @click="exit()">
                        Exit
                    </action-button>
                </template>
            </onboarding-header>

            <div class="pt-5 ma-5">
                <div class="container fluid">
                    <div class="layout align-top mb-5 justify-center row">
                        <div class="mr-4">
                            <img
                                class="top-logo"
                                :src="'/img/platforms/' + channelIcon + '.svg'">
                        </div>
                        <div class="top-info">
                            <h2 class="mb-3">
                                {{ channelDetails.name }} Setup is Complete! What would you like to do next?
                            </h2>
                            <div
                                v-if="onboardingHasPlaybooks"
                                class="top-desc">
                                You've successfully setup {{ channelDetails.name }}. If you're ready to start using BuyerBridge you can exit onboarding and begin exploring.
                                Otherwise, if you're in setup mode and want to knock other channels out now go ahead!
                            </div>
                            <div
                                v-else-if="onboardingHasMarketplace"
                                class="top-desc">
                                You've successfully setup {{ channelDetails.name }}. Vehicle listings can take up to 72 hours to display on {{ channelDetails.name }}.
                                In the meantime we encourage you to explore BuyerBridge and take a second glance at
                                <router-link
                                    :to="{
                                        name: 'inventory',
                                        params: {
                                            dealer_id: dealer.id
                                        }
                                    }">
                                    <span>your inventory</span>
                                </router-link>.
                            </div>
                            <div
                                v-else
                                class="top-desc">
                                You've successfully setup {{ channelDetails.name }}. If you're ready to start deploying some Playbooks You can exit onboarding and browse
                                our extensive library of playbooks. Otherwise, if you're in setup mode and want to knock other channels out now go ahead!
                                You can deploy Playbooks later.
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!noChannels"
                        class="layout align-center justify-space-around row px-5 card-holder">
                        <div class="card">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-5">
                                <div class="layout align-center justify-start justify-space-around row">
                                    <div class="content content-first">
                                        <div>
                                            <h2 class="">
                                                Setup Other Channels
                                            </h2>
                                            <div class="mt-3">
                                                Clearly you're in setup mode! Select the channel you'd like to setup next below or click
                                                "Learn More" if you'd like to know more about the channel before diving in.
                                            </div>
                                        </div>

                                        <div class="additional-channels">
                                            <loader v-if="loading" />
                                            <div
                                                v-if="notEnrolledChannels.length > 0"
                                                class="channel-setup">
                                                <div
                                                    v-for="channel in notEnrolledChannelsFiltered"
                                                    :key="channel.id"
                                                    class="channel">
                                                    <div class="badge">
                                                        <img
                                                            class="logo"
                                                            :src="'/img/platforms/' + channel.name + '.svg'">
                                                        <div class="name">
                                                            {{ channel.display_name }}
                                                        </div>
                                                        <router-link
                                                            v-if="channel.authStatus"
                                                            class="setup-button"
                                                            :to="{ name: 'channel-setup-product-selection', params: { dealer_id: dealer.id, channel: channel.name }}">
                                                            SETUP
                                                        </router-link>
                                                        <styled-button
                                                            v-else
                                                            class="setup-button authenticate"
                                                            @click="openAuthenticateModal(channel)">
                                                            AUTHENTICATE AND SETUP
                                                        </styled-button>
                                                        <p
                                                            v-if="channel.name == 'facebook'"
                                                            target="_blank"
                                                            class="learn-more">
                                                            <a
                                                                href="https://buyerbridge.io/facebook-automotive-inventory-ads/"
                                                                target="_blank">Learn More</a>
                                                        </p>
                                                        <p
                                                            v-else-if="channel.name == 'microsoft'"
                                                            target="_blank"
                                                            class="learn-more">
                                                            <a
                                                                href="https://support.buyerbridge.io/knowledge/introducing-msn-autos-marketplace-pilot-launch"
                                                                target="_blank">Learn More</a>
                                                        </p>
                                                        <p
                                                            v-else-if="channel.name == 'snapchat'"
                                                            target="_blank"
                                                            class="learn-more">
                                                            <a
                                                                href="https://buyerbridge.io/social-platforms/snapchat/"
                                                                target="_blank">Learn More</a>
                                                        </p>
                                                        <p
                                                            v-else-if="channel.name == 'tiktok'"
                                                            target="_blank"
                                                            class="learn-more">
                                                            <a
                                                                href="https://buyerbridge.io/social-platforms/tiktok/"
                                                                target="_blank">Learn More</a>
                                                        </p>
                                                        <p
                                                            v-else-if="channel.name == 'pinterest'"
                                                            class="learn-more">
                                                            <a
                                                                href="https://buyerbridge.io/social-platforms/pinterest/"
                                                                target="_blank">Learn More</a>
                                                        </p>
                                                        <p
                                                            v-else-if="channel.name == 'google'"
                                                            class="learn-more">
                                                            <a
                                                                href="https://support.buyerbridge.com/knowledge/google-vehicle-listings"
                                                                target="_blank">Learn More</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                        <div class="text-center">
                            <h2>or</h2>
                        </div>

                        <div
                            v-if="onboardingHasPlaybooks"
                            class="card">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-5">
                                <div class="layout align-center justify-start row">
                                    <div class="content">
                                        <div>
                                            <h2 class="">
                                                Deploy Playbooks
                                            </h2>
                                            <div class="mt-3 mb-4">
                                                Did you know BuyerBridge offers a library of hundreds of pre-made creative
                                                solutions that can be deployed across multiple channels? Check them out!
                                            </div>
                                        </div>

                                        <div class="button-holder">
                                            <styled-button
                                                class="styled-button"
                                                :to="{ name: 'dealer-playbooks', params: { dealer_id: dealer.id }}">
                                                BROWSE PLAYBOOKS
                                            </styled-button>
                                        </div>

                                        <div class="pt-4 image-holder">
                                            <img
                                                src="/img/playbook_cards.png">
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>

                        <div
                            v-else-if="onboardingHasMarketplace"
                            class="card">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-5">
                                <div class="layout align-center justify-start row">
                                    <div class="content marketplace-content">
                                        <div>
                                            <h2 class="">
                                                Review Inventory
                                            </h2>
                                            <div class="mt-3 mb-4">
                                                While you're waiting on your inventory to be posted
                                                to {{ channelDetails.name }}, which can take up to
                                                72 hours, we highly encourage you to review your
                                                inventory to ensure details like price &amp; VDP
                                                URLs are correct.
                                            </div>
                                        </div>

                                        <div class="button-holder">
                                            <styled-button
                                                class="styled-button"
                                                :to="{
                                                    name: 'inventory',
                                                    params: {
                                                        dealer_id: dealer.id
                                                    }
                                                }">
                                                VIEW INVENTORY
                                            </styled-button>
                                        </div>

                                        <div class="pt-4 image-holder">
                                            <img
                                                src="/img/inventory-syndication.svg">
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>

                        <div
                            v-else
                            class="card lite-card">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-5">
                                <div class="layout align-center justify-start row">
                                    <div class="content">
                                        <div>
                                            <h2 class="">
                                                Start Using BuyerBridge
                                            </h2>
                                            <div class="mt-3 mb-4">
                                                With your
                                                <product-logo
                                                    v-if="primaryProductId"
                                                    :value="primaryProductId"
                                                    :name="primaryProduct.display_name"
                                                    type="horizontal"
                                                    height="24px" />
                                                package you're able to begin reporting at an entirely new level.
                                                To get started click link below.
                                            </div>
                                        </div>

                                        <div class="button-holder pb-5">
                                            <styled-button
                                                class="styled-button"
                                                :to="{ name: 'global-dashboard', params: { dealer_id: dealer.id }}">
                                                VIEW DASHBOARD
                                            </styled-button>
                                        </div>

                                        <div class="pt-5 pb-4 image-holder">
                                            <div class="graph" />
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                    </div>

                    <!-- second view if no setups -->
                    <div
                        v-if="noChannels"
                        class="layout align-center justify-space-around row px-5 card-holder">
                        <div
                            v-if="onboardingHasPlaybooks"
                            class="widecard">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-4">
                                <div class="layout align-center justify-start row">
                                    <div class="widecontent">
                                        <div class="pt-4 image-holder">
                                            <div class="image-abs">
                                                <img
                                                    src="/img/playbook_cards.png">
                                            </div>
                                        </div>
                                        <div class="text">
                                            <h2>
                                                Deploy Playbooks
                                            </h2>
                                            <div class="mt-3 mb-4">
                                                Did you know BuyerBridge offers a library of hundreds of pre-made creative
                                                solutions that can be deployed across multiple channels? Check them out!
                                            </div>
                                        </div>

                                        <div class="button-holder">
                                            <styled-button
                                                class="styled-button"
                                                :to="{ name: 'dealer-playbooks', params: { dealer_id: dealer.id }}">
                                                BROWSE PLAYBOOKS
                                            </styled-button>
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                        <div
                            v-else-if="onboardingHasMarketplace"
                            class="widecard">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-4">
                                <div class="layout align-center justify-start row">
                                    <div class="widecontent widecontent-marketplace">
                                        <img
                                            src="/img/inventory-syndication.svg">

                                        <div class="text">
                                            <h2>
                                                Review Inventory
                                            </h2>
                                            <div class="mt-3 mb-4">
                                                While you're waiting on your inventory to be posted
                                                to {{ channelDetails.name }}, which can take up to
                                                72 hours, we highly encourage you to review your
                                                inventory to ensure details like price &amp; VDP
                                                URLs are correct.
                                            </div>
                                        </div>

                                        <div class="button-holder">
                                            <styled-button
                                                class="styled-button"
                                                :to="{
                                                    name: 'inventory',
                                                    params: {
                                                        dealer_id: dealer.id
                                                    }
                                                }">
                                                VIEW INVENTORY
                                            </styled-button>
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                        <div
                            v-else
                            class="card lite-card">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="pa-5">
                                <div class="layout align-center justify-start row">
                                    <div class="content">
                                        <div>
                                            <h2 class="">
                                                Start Using BuyerBridge
                                            </h2>
                                            <div class="mt-3 mb-4">
                                                With your
                                                <product-logo
                                                    v-if="primaryProductId"
                                                    :value="primaryProductId"
                                                    :name="primaryProduct.display_name"
                                                    type="horizontal"
                                                    height="24px" />
                                                package you're able to begin reporting at an entirely new level.
                                                To get started click link below.
                                            </div>
                                        </div>

                                        <div class="button-holder pb-5">
                                            <styled-button
                                                class="styled-button"
                                                :to="{ name: 'global-dashboard', params: { dealer_id: dealer.id }}">
                                                VIEW DASHBOARD
                                            </styled-button>
                                        </div>

                                        <div class="pt-5 pb-4 image-holder">
                                            <div class="graph" />
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                    </div>


                    <div class="bottom">
                        <router-link
                            v-if="onboardingHasMarketplace"
                            :to="{
                                name: 'inventory',
                                params: {
                                    dealer_id: dealer.id
                                }
                            }">
                            Done onboarding? Click here to exit to the inventory dashboard
                        </router-link>
                        <router-link
                            v-else
                            :to="{
                                name: 'global-dashboard',
                                params: {
                                    dealer_id: dealer.id
                                }
                            }">
                            Done onboarding? Click here to exit to the dashboard
                        </router-link>
                    </div>
                </div>
            </div>
            <platform-authentication-slide-out
                v-model="showAuthenticateSlideOut"
                :channel="authenticateModalData"
                slide-out-title="Set up Token user"
                :redirect-path="'/dealers/' + dealer.id + '/channel-setup/' + authenticateModalData.name" />
        </styled-interface>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductLogo from '@/components/globals/ProductLogo';
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import ActionButton from '@/components/globals/ActionButton';
import StyledInterface from '@/components/globals/StyledInterface';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import Loader from '@/components/globals/Loader';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';
import { getChannelByPlatformId } from './channel-setup/channels';
import { PLATFORM_FACEBOOK, FEATURE_PLAYBOOK, MARKETPLACE_PRODUCTS, PRODUCTS } from '@/helpers/globals';
import { getNotEnrolledChannels } from '@/helpers/getNotEnrolledChannels';
import { getAvailableChannels } from '@/helpers/getAvailableChannels';

    export default {
        name: 'OnboardingComplete',
        title: 'Onboarding Complete',
        components: {
            ProductLogo,
            StyledCard,
            StyledButton,
            ActionButton,
            StyledInterface,
            OnboardingHeader,
            Loader,
            PlatformAuthenticationSlideOut
        },
        data() {
            return {
                loading: false,
                availableChannels: [],
                notEnrolledChannels: {},
                noChannels: false,
                showAuthenticateSlideOut: false,
                authenticateModalData: {},
                liteOnboarding: false
            };
        },
        computed: {
            ...mapGetters([
                'dealerHasFeatures',
                'dealerPlatforms',
                'dealerProducts'
            ]),
            ...mapState({
                dealer: state => state.dealer.currentDealer,
                agency: state => state.agency.currentAgency
            }),
            onboardingHasPlaybooks() {
                return this.dealerProducts
                    .filter(product => (
                        this.productIds.includes(product.id)
                    ))
                    .some(product => (
                        product.features?.data?.some(feature => (
                            feature.name === FEATURE_PLAYBOOK
                        ))
                    ));
            },
            onboardingHasMarketplace() {
                return MARKETPLACE_PRODUCTS.some(productId => (
                    this.productIds.includes(productId)
                ));
            },
            callTrackingNumbers() {
                return this.dealer?.twilio_lead_sources.data || [];
            },
            channelFullName() {
                return this.$route.query.channel;
            },
            channelId() {
                return parseInt(this.$route.query.channel_id);
            },
            /**
             * Ensure IDs are always an array of integers
             */
            productIds() {
                if (Array.isArray(this.$route.query.product_ids)) {
                    return this.$route.query.product_ids.map(parseInt);
                }

                return [ parseInt(this.$route.query.product_ids) ];
            },
            primaryProduct() {
                return PRODUCTS.find(product => (
                    this.productIds[0] === product.id
                ));
            },
            primaryProductId() {
                return this.primaryProduct?.id ?? 0;
            },
            channelDetails() {
                return getChannelByPlatformId(this.channelId) ?? {};
            },
            channelIcon() {
                return this.channelDetails.key ?? PLATFORM_FACEBOOK;
            },
            dealerChannels() {
                const channels = this.dealerPlatforms.map(platform => {
                    return {
                        ...platform,
                        products: this.dealerProducts.filter( product => {
                            const visibility = product.product_settings?.visibility;
                            return product.platform_id == platform.id && visibility !== 'coming_soon';
                        })
                    };
                });
                return channels;
            },
            enrolledChannels() {
                return this.dealerChannels.filter(channel => channel.products.some( product => {
                    const visibility = product.product_settings?.visibility;
                    return product.public == true
                        && visibility !== 'coming_soon';
                }));
            },
            notEnrolledChannelsFiltered() {
                // making sure we will not have the same channel
                return this.notEnrolledChannels.filter(channel => channel.name != this.channelDetails.name);
            }
        },
        async created() {
            if(this.dealerProducts[0].id == 30
            || this.dealerProducts[0].id == 31
            || this.dealerProducts[0].id == 32
            || this.dealerProducts[0].id == 33 ) {
                this.liteOnboarding = true;
            }
            this.loading = true;
            await this.loadDealerPlatforms();
            this.notEnrolledChannels = await getNotEnrolledChannels(this.availableChannels, this.enrolledChannels, this.agency.id, true);
            if (!this.notEnrolledChannels.length) {
                this.noChannels = true;
            }
            this.loading = false;
        },
        methods: {
            exit() {
                this.$router.push({ name: 'home' });
            },
            async loadDealerPlatforms() {
                const platforms = await this.$apiRepository.getPlatforms();
                this.availableChannels = getAvailableChannels(platforms);
            },
            openAuthenticateModal(data) {
                this.showAuthenticateSlideOut = true;
                this.authenticateModalData = data;
            },
        },
    };
</script>

<style lang="scss" scoped>
.top-info{
    max-width: 900px;
}
.top-logo{
    max-width: 50px;
    height: auto;
}
.top-desc{
    line-height: 26px;
    font-size: 14px;
}
.bottom{
    text-align: center;
    margin-top: 45px;
    font-weight: 700;
}
.card-holder{
    align-items: flex-start;
    justify-content: center;
}
.card{
    width: 45%;
    max-width: 550px;
}
.graph{
    position: absolute;
    z-index: 1;
    left:-48px;
    right:-48px;
    bottom:-48px;
    top:105px;
    background-image: url('/img/graph.png');
    background-repeat: no-repeat;
    background-size:contain;
    background-position: bottom;
}
.advt-lite{
    width: 138px;
    height: 13px;
    margin-right: 2px;
    transform: translateY(1px);
}
.product-logo {
    position: relative;
    margin: 0 5px;
    top: 5px;
}
.content{
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.marketplace-content {
    .image-holder {
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: -158px;
        }
    }
}
.content-first {
    justify-content: flex-start;
}
.image-holder {
    text-align: center;
    img {
        max-width: 360px;
        height:auto;
        margin-bottom: -54px;
    }
}
.text-center {
    width: 10%;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-holder{
    position: relative;
    z-index: 9;
    text-align: center;
}
.styled-button{
    font-size: 16px;
    font-weight: 700;
}
.widecard{
    margin-top: 45px;
    .styled-card{
        overflow: visible !important;
    }
}
.widecontent{
    display: flex;
    align-items: center;
    .image-holder{
        width: 27%;
    }
    .image-abs{
        position: absolute;
        bottom:-29px;
        left:-55px;
        z-index: 9;
        width: 29%;
        img{
            width: 350px;
            height: 200px;
            margin-bottom: 0;
            max-width: 100%;
        }
    }
    .text{
        width: 46%;
    }
    .button-holder{
        width: 29%;
    }
}
.widecontent-marketplace {
    img {
        height: 140px;
        width: auto;
        margin-right: 60px;
    }
}
.additional-channels {
    display: flex;
    justify-content: center;
    .messaging {
        display: flex;
        flex-direction: column;
        max-width: 440px;
        padding: 30px;
        h2 {
            margin-bottom: 15px;
        }
        .learn-more {
            margin-top: 5px;
            align-self: flex-end;
        }
    }
    .channel-setup {
        padding-top:30px;
        .channel{
            display: inline-block;
            margin: 15px;
            margin-bottom: 30px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .badge {
            width: 120px;
            text-align: center;

            img {
                width: 30px;
                height: auto;
            }
            .name{
                margin-bottom: 10px;
            }
            .coming-soon {
                margin-top: 10px;
                font-size: 10px;
                font-weight: 700;
                color: $carnation;
            }
            .setup-button {
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $blue-bg;
                color: $white;
                font-weight: 600;
                border-radius: 5px;
                padding: 5px 10px;
                height: auto;
                width: 90px;
                margin: 0 auto;
            }
            .authenticate{
                font-size: 11px;
            }
            .learn-more {
                margin-top: 5px;
            }
        }
    }
}
</style>